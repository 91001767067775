<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="설비 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :required="true"
                  :editable="editable && !updateMode"
                  label="설비관리번호"
                  name="equipmentNo"
                  v-model="data.equipmentNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="설비명"
                  name="equipmentName"
                  v-model="data.equipmentName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-equip-class
                  :required="true"
                  :editable="editable"
                  label="설비유형"
                  name="equipmentTypeCd"
                  v-model="data.equipmentTypeCd">
                </c-equip-class>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  :required="true"
                  :editable="editable && !updateMode"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept type="edit" :required="true" label="관리부서" name="managementDepts" v-model="data.managementDepts" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-process
                  :editable="editable"
                  label="단위공정"
                  multiple="multiple"
                  :required="true"
                  name="subProcessCd"
                  v-model="data.subProcessCd">
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="수량"
                  name="amount"
                  type="number"
                  v-model="data.amount">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="설치관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="설비위치"
                  name="equipmentLocation"
                  v-model="data.equipmentLocation">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                    label="설치일"
                    name="installDate"
                    v-model="data.installDate"
                  />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="설치업체"
                  name="installVendor"
                  v-model="data.installVendor">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                    label="가동일"
                    name="opertationDate"
                    v-model="data.opertationDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="설치상태 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="검사합격번호"
                  name="checkPassNo"
                  v-model="data.checkPassNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                    label="합격일"
                    name="checkPassDate"
                    v-model="data.checkPassDate"
                  />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                    label="폐기일"
                    name="discardDate"
                    v-model="data.discardDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="점검관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-radio
                  :editable="editable"
                  :comboItems="flagItems"
                  label="법정설비여부"
                  name="lawEquipmentFlag"
                  v-model="data.lawEquipmentFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  :editable="editable"
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="inspectionCycleCd"
                  label="법정점검주기"
                  v-model="data.inspectionCycleCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  :editable="false"
                  codeGroupCd="MDM_EQUIP_GRADE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="equipGradeCd"
                  label="설비등급"
                  v-model="data.equipGradeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="selfCycleCd"
                  label="자체점검주기"
                  v-model="data.selfCycleCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="pmCycleCd"
                  label="예방정비주기"
                  v-model="data.pmCycleCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-radio
                  :editable="editable"
                  :comboItems="flagItems"
                  label="예방정비여부"
                  name="pmFlag"
                  v-model="data.pmFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-radio
                  :editable="editable"
                  :comboItems="flagItems"
                  label="예측정비여부"
                  name="predictiveFlag"
                  v-model="data.predictiveFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-radio
                  :editable="editable"
                  :comboItems="flagItems"
                  label="고장정비여부"
                  name="cmFlag"
                  v-model="data.cmFlag">
                </c-radio>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="법규관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-multi-select
                  :editable="editable"
                  codeGroupCd="REGULATION_BILL_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="관련 법규"
                  name="relatedLaws"
                  maxValues="3"
                  v-model="data.relatedLaws">
                </c-multi-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-radio
                  :editable="editable"
                  :comboItems="psmFlagItems"
                  label="PSM대상"
                  name="psmFlag"
                  v-model="data.psmFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-radio
                  :editable="editable"
                  :comboItems="flagItems"
                  label="유해위험기계기구 여부"
                  name="hazardousFlag"
                  v-model="data.hazardousFlag">
                </c-radio>
              </div>
            </template>
          </c-card>
          <c-card title="폐기" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  :editable="editable"
                  :comboItems="discardFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="discardFlag"
                  label="폐기여부"
                  v-model="data.discardFlag"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                    :editable="editable && data.discardFlag=='Y'"
                    label="폐기일"
                    name="discardDate"
                    v-model="data.discardDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-info',
  watch: {
    'data.discardFlag': function() {
      if (this.data.discardFlag == 'N') {
        this.data.discardDate = '';
      }
    }
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      saveUrl: transactionConfig.mdm.equipment.info.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      discardFlagItems: [],
      data: {
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        mainProcessCd: '',  // 주공정
        subProcessCd: '',  // 부공정
        managementDepts: '',  // 관리부서
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        installVendor: '',  // 설치업체
        opertationDate: '',  // 가동일자
        checkPassNo: '',  // 검사합격번호
        checkPassDate: '',  // 합격일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        psmFlag: 'N',  // PSM 대상 여부
        amount: '',  // 수량
        lawEquipmentFlag: 'N',  // 법정설비 여부
        inspectionCycleCd: null,  // 법정점검 주기
        selfCycleCd: null,  // 자체점검 주기
        pmCycleCd: null,  // 자체점검 주기
        pmFlag: 'N',  // 예방정비 여부
        cmFlag: 'N',  // 고장정비 여부
        predictiveFlag: 'N',  // 예지정비 여부
        relatedLaws: '',  // 관련법규
        recentInspectionDate: '',  // 최근 점검일자
        outsourcingVendor: '',  // 유지보수업체
        hazardousFlag: 'N'
      },
      flagItems: [],
      psmFlagItems: []
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.discardFlagItems = [
        { code: 'N', codeName: this.$language('미폐기') },
        { code: 'Y', codeName: this.$language('폐기') },
      ]
      this.flagItems = [
        { code: 'Y', codeName: this.$language('해당') },
        { code: 'N', codeName: this.$language('해당없음') },
      ]
      this.psmFlagItems = [
        { code: 'Y', codeName: this.$language('대상') },
        { code: 'N', codeName: this.$language('비대상') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.equipment.get.url;
      this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
      this.checkUrl = selectConfig.mdm.equipment.check.url;
      this.getDetail();
    },
    getDetail() {
      if (this.param.equipmentCd) {
        this.$http.url = this.detailUrl;
        this.$http.param = {equipmentCd: this.param.equipmentCd, plantCd: this.param.plantCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.param.plantName = _result.data.plantName;
          this.param.plantCd = _result.data.plantCd;
          this.param.equipmentCd = _result.data.equipmentCd;
          this.param.equipmentNo = _result.data.equipmentNo;
          this.param.equipmentTypeName = _result.data.equipmentTypeName;
          this.param.equipmentName = _result.data.equipmentName;
          this.param.processCds = _result.data.processCds;
        },);
      }
    },
    reset() {
    },
    saveInfo() {
      if (this.param.equipmentCd) {
        this.saveUrl = transactionConfig.mdm.equipment.info.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.equipmentNo);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;
                  this.isSave = true;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '동일한 시퀀스 아이디가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.equipmentCd = result.data.equipmentCd;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$emit('insertEquipInfo', result.data.equipmentCd);
      }
      this.param.equipmentCd = result.data.equipmentCd
      this.getDetail();
    },
  }
};
</script>